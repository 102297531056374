<template>
  <LastBetsSportTable
    :bets="lastBigBets"
    :is-loading="pending"
    :mobile="props.mobile"
  />
</template>

<script setup lang="ts">
import LastBetsSportTable from '../tables/LastBetsCasinoTable.vue'
import { useLastBigBetsCasino } from '../../../../composables/LastBets/useLastBigBetsCasino'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
const { lastBigBets, pending } = useLastBigBetsCasino(props.mobile ? 8 : 10)
</script>
