<template>
  <LastBetsSportTable
    :bets="lastSportBigBets"
    :is-loading="pending"
    :mobile="props.mobile"
  />
</template>

<script setup lang="ts">
import LastBetsSportTable from '../tables/LastBetsSportTable.vue'
import { useLastBigBetsSport } from '../../../../composables/LastBets/useLastBigBetsSport'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
const { lastSportBigBets, pending } = useLastBigBetsSport(props.mobile ? 8 : 10)
</script>
