<template>
  <div class="casino-last-bets">
    <template v-if="props.isLoading">
      <StSkeletonLoader
        v-for="i in skeletonAmount"
        :key="i"
        height="52px"
        bg-color="var(--background-secondary)"
        :shimmer-opacity="0.28"
      />
    </template>
    <template v-else>
      <slot />
      <div v-if="haveElements" class="shadow" />
    </template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isLoading: boolean
    haveElements: boolean
    skeletonAmount: number
    mobile?: boolean
  }>(),
  {
    skeletonAmount: 5,
    mobile: false,
  },
)

const shadowHeight = computed(() => (props.mobile ? '76px' : '96px'))
</script>

<style scoped>
.casino-last-bets {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.shadow {
  pointer-events: none;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: v-bind(shadowHeight);

  background: linear-gradient(0deg, #12121a 0%, rgb(18 18 26 / 0%) 100%);
}
</style>
