import type { TSportEvent } from 'markets-store'
import { useMarketsStore } from './useMarketsStore'

/**
 * Получение спорт события по id.
 * Можно передать number, ref или getter
 *
 * @example
 * const event = useEventById(() => props.eventId)
 * const otherEvent = useEventById(123)
 */
export function useEventById(
  id: number | Ref<number> | (() => number),
): ComputedRef<TSportEvent> {
  const MS = useMarketsStore()

  return computed<TSportEvent>(() => {
    const eventId = toValue(id)
    // TODO вообще говоря может вернуться undefined, надо поправить тип и исправить ошибки
    return MS.sportEvents.getById(eventId) as TSportEvent
  })
}
