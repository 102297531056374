<template>
  <section>
    <LastBetsHeader v-if="!mobile" />
    <LastBetsWrapper
      :is-loading="isLoading"
      :have-elements="!!props.bets?.length"
      :skeleton-amount="mobile ? 8 : 10"
      :mobile="mobile"
    >
      <LastCasinoBet
        v-for="bet in props.bets"
        :key="bet.roundId + bet.sessionId + bet.updatedAt"
        :bet="bet"
        :mobile="mobile"
      />
    </LastBetsWrapper>
  </section>
</template>

<script setup lang="ts">
import LastBetsHeader from '../LastBetsHeader.vue'
import type { CasinoLastBet } from '../../types'
import LastCasinoBet from '../LastCasinoBet.vue'
import LastBetsWrapper from '../LastBetsWrapper.vue'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
    bets: CasinoLastBet[] | null
    isLoading: boolean
  }>(),
  {
    mobile: false,
  },
)
</script>
